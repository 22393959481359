<template>
  <div>
    <v-card class="px-10">
      <br>
      <p><strong>PRIVACY NOTICE</strong></p>
      <p><strong>Last updated November 12, 2021</strong></p>
      <p>
        Thank you for choosing to be part of our community at FantasyKombat ("<strong>Company</strong>,"
        "<strong>we</strong>," "<strong>us</strong>," or "<strong>our</strong>"). We are committed to protecting your
        personal information and your right to privacy. If you have any questions or concerns about this privacy notice
        or our practices with regard to your personal information, please contact us at info@FantasyKombat.com.
      </p>
      <p>This privacy notice describes how we might use your information if you:</p>
      <ul>
        <li><p>Visit our website at <a href="https://www.fantasykombat.com">https://www.fantasykombat.com</a></p></li>
        <li><p>Download and use our mobile application — FantasyKombat</p></li>
        <li><p>Engage with us in other related ways ― including any sales, marketing, or events</p></li>
      </ul>
      <p>In this privacy notice, if we refer to:</p>
      <ul>
        <li>
          <p>
            "<strong>Website</strong>," we are referring to any website of ours that references or links to this
            policy
          </p>
        </li>
        <li>
          <p>
            "<strong>App</strong>," we are referring to any application of ours that references or links to this
            policy, including any listed above
          </p>
        </li>
        <li>
          <p>
            "<strong>Services</strong>," we are referring to our Website,&nbsp;App,&nbsp;and other related services,
            including any sales, marketing, or events
          </p>
        </li>
      </ul>
      <p>
        The purpose of this privacy notice is to explain to you in the clearest way possible what information we
        collect, how we use it, and what rights you have in relation to it. If there are any terms in this privacy
        notice that you do not agree with, please discontinue use of our Services immediately.
      </p>
      <p>
        <strong>Please read this privacy notice carefully, as it will help you understand what we do with the
          information that we collect.</strong>
      </p>
      <p><strong>1. WHAT INFORMATION DO WE COLLECT?</strong></p>
      <p><strong>Personal information you disclose to us</strong></p>
      <p><strong><em>In Short:&nbsp;</em></strong><em>We collect personal information that you provide to us.</em></p>
      <p>
        We collect personal information that you voluntarily provide to us when you register on the&nbsp;Services,
        express an interest in obtaining information about us or our products and Services, when you participate in
        activities on the Services (such as by posting messages in our online forums or entering competitions, contests
        or giveaways)&nbsp;or otherwise when you contact us.
      </p>
      <p>
        The personal information that we collect depends on the context of your interactions with us and the Services,
        the choices you make and the products and features you use. The personal information we collect may include the
        following:
      </p>
      <p>
        <strong>Personal Information Provided by You.</strong> We collect names;&nbsp;email addresses;&nbsp;usernames;&nbsp;passwords;&nbsp;debit/credit
        card numbers;&nbsp;contact or authentication data;&nbsp;and other similar information.
      </p>
      <p>
        <strong>Payment Data.</strong> We may collect data necessary to process your payment if you make purchases,
        such as your payment instrument number (such as a credit card number), and the security code associated with
        your payment instrument.
      </p>
      <p>
        <strong>Social Media Login Data.&nbsp;</strong>We may provide you with the option to register with us using
        your existing social media account details, like your Facebook, Twitter or other social media account. If you
        choose to register in this way, we will collect the information described in the section called "<a
          href="#sociallogins"
        >HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a>" below.
      </p>
      <p>
        All personal information that you provide to us must be true, complete and accurate, and you must notify us of
        any changes to such personal information.
      </p>
      <p><strong>Information automatically collected</strong></p>
      <p>
        <strong><em>In Short:&nbsp;</em></strong><em>Some information — such as your Internet Protocol (IP) address
          and/or browser and device characteristics — is collected automatically when you visit our Services.</em>
      </p>
      <p>
        We automatically collect certain information when you visit, use or navigate the Services. This information
        does not reveal your specific identity (like your name or contact information) but may include device and usage
        information, such as your IP address, browser and device characteristics, operating system, language
        preferences, referring URLs, device name, country, location, information about how and when you use our Services
        and other technical information. This information is primarily needed to maintain the security and operation of
        our Services, and for our internal analytics and reporting purposes.
      </p>
      <p>Like many businesses, we also collect information through cookies and similar technologies.</p>
      <p>The information we collect includes:</p>
      <ul>
        <li>
          <p>
            <em>Log and Usage Data.</em> Log and usage data is service-related, diagnostic, usage and performance
            information our servers automatically collect when you access or use our Services and which we record in log
            files. Depending on how you interact with us, this log data may include your IP address, device information,
            browser type and settings and information about your activity in the Services(such as the date/time stamps
            associated with your usage, pages and files viewed, searches and other actions you take such as which features
            you use), device event information (such as system activity, error reports (sometimes called 'crash dumps')
            and hardware settings).
          </p>
        </li>
        <li>
          <p>
            <em>Device Data.</em> We collect device data such as information about your computer, phone, tablet or
            other device you use to access the Services. Depending on the device used, this device data may include
            information such as your IP address (or proxy server), device and application identification numbers,
            location, browser type, hardware model Internet service provider and/or mobile carrier, operating system and
            system configuration information.
          </p>
        </li>
        <li>
          <p>
            <em>Location Data.</em> We collect location data such as information about your device's location, which
            can be either precise or imprecise. How much information we collect depends on the type and settings of the
            device you use to access the Services. For example, we may use GPS and other technologies to collect
            geolocation data that tells us your current location (based on your IP address). You can opt out of allowing
            us to collect this information either by refusing access to the information or by disabling your Location
            setting on your device. Note however, if you choose to opt out, you may not be able to use certain aspects of
            the Services.
          </p>
        </li>
      </ul>
      <p><strong>Information collected through our App</strong></p>
      <p>
        <strong><em>In Short:&nbsp;</em></strong><em>We collect information regarding your geolocation, push
          notifications, when you use our App.</em>
      </p>
      <p>If you use our App, we also collect the following information:</p>
      <ul>
        <li>
          <p>
            <em>Geolocation Information.</em> We may request access or permission to and track location-based
            information from your mobile device, either continuously or while you are using our App, to provide certain
            location-based services. If you wish to change our access or permissions, you may do so in your device's
            settings.
          </p>
        </li>
        <li>
          <p>
            <em>Push Notifications.&nbsp;</em>We may request to send you push notifications regarding your account or
            certain features of the App. If you wish to opt-out from receiving these types of communications, you may turn
            them off in your device's settings.
          </p>
        </li>
      </ul>
      <p>
        This information is primarily needed to maintain the security and operation of our App, for troubleshooting and
        for our internal analytics and reporting purposes.
      </p>
      <p><strong>Information collected from other sources</strong></p>
      <p>
        <strong><em>In Short: &nbsp;</em></strong>_We may collect limited data from public databases, marketing
        partners, _social media platforms,_ and other outside sources._
      </p>
      <p>
        In order to enhance our ability to provide relevant marketing, offers and services to you and update our
        records, we may obtain information about you from other sources, such as public databases, joint marketing
        partners, affiliate programs, data providers, social media platforms, as well as from other third parties. This
        information includes mailing addresses, job titles, email addresses, phone numbers, intent data (or user
        behavior data), Internet Protocol (IP) addresses, social media profiles, social media URLs and custom profiles,
        for purposes of targeted advertising and event promotion.If you interact with us on a social media platform
        using your social media account (e.g. Facebook or Twitter), we receive personal information about you such as
        your name, email address, and gender. Any personal information that we collect from your social media account
        depends on your social media account's privacy settings.
      </p>
      <p><strong>2. HOW DO WE USE YOUR INFORMATION?</strong></p>
      <p>
        <strong><em>In Short: &nbsp;</em></strong><em>We process your information for purposes based on legitimate
          business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your
          consent.</em>
      </p>
      <p>
        We use personal information collected via our Services for a variety of business purposes described below. We
        process your personal information for these purposes in reliance on our legitimate business interests, in order
        to enter into or perform a contract with you, with your consent, and/or for compliance with our legal
        obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.
      </p>
      <p>We use the information we collect or receive:</p>
      <ul>
        <li>
          <p>
            <strong>To facilitate account creation and logon process.</strong> If you choose to link your account
            with us to a third-party account (such as your Google or Facebook account), we use the information you allowed
            us to collect from those third parties to facilitate account creation and logon process for the performance of
            the contract.See the section below headed "<a href="#sociallogins">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a>"
            for further information.
          </p>
        </li>
        <li>
          <p>
            <strong>To post testimonials.</strong> We post testimonials on our Services that may contain personal
            information. Prior to posting a testimonial, we will obtain your consent to use your name and the content of
            the testimonial. If you wish to update, or delete your testimonial, please contact us at
            info@fantasykombat.com and be sure to include your name, testimonial location, and contact information.
          </p>
        </li>
        <li>
          <p>
            <strong>Request feedback.&nbsp;</strong>We may use your information to request feedback and to contact
            you about your use of our Services.
          </p>
        </li>
        <li>
          <p>
            <strong>To enable user-to-user communications.</strong> We may use your information in order to enable
            user-to-user communications with each user's consent.
          </p>
        </li>
        <li>
          <p>
            <strong>To manage user accounts.&nbsp;</strong>We may use your information for the purposes of managing
            our account and keeping it in working order.
          </p>
        </li>
        <li>
          <p>
            <strong>To send administrative information to you.&nbsp;</strong>We may use your personal information to
            send you product, service and new feature information and/or information about changes to our terms,
            conditions, and policies.
          </p>
        </li>
        <li>
          <p>
            <strong>To protect our Services.&nbsp;</strong>We may use your information as part of our efforts to keep
            our Services safe and secure (for example, for fraud monitoring and prevention).
          </p>
        </li>
        <li>
          <p>
            <strong>To enforce our terms, conditions and policies for business purposes, to comply with legal and
              regulatory requirements or in connection with our contract.</strong>
          </p>
        </li>
        <li>
          <p>
            <strong>To respond to legal requests and prevent harm.&nbsp;</strong>If we receive a subpoena or other
            legal request, we may need to inspect the data we hold to determine how to respond.
          </p>
        </li>
        <li>
          <p>
            <strong>Fulfill and manage your orders.&nbsp;</strong>We may use your information to fulfill and manage
            your orders, payments, returns, and exchanges made through the Services.
          </p>
        </li>
        <li>
          <p>
            <strong>Administer prize draws and competitions.</strong> We may use your information to administer prize
            draws and competitions when you elect to participate in our competitions.
          </p>
        </li>
        <li>
          <p>
            <strong>To deliver and facilitate delivery of services to the user.</strong> We may use your information
            to provide you with the requested service.
          </p>
        </li>
        <li>
          <p>
            <strong>To respond to user inquiries/offer support to users.</strong> We may use your information to
            respond to your inquiries and solve any potential issues you might have with the use of our Services.
          </p>
        </li>
        <li>
          <p>
            <strong>To send you marketing and promotional communications.</strong> We and/or our third-party
            marketing partners may use the personal information you send to us for our marketing purposes, if this is in
            accordance with your marketing preferences. For example, when expressing an interest in obtaining information
            about us or our Services, subscribing to marketing or otherwise contacting us, we will collect personal
            information from you. You can opt-out of our marketing emails at any time (see the "<a href="#privacyrights">WHAT
              ARE YOUR PRIVACY RIGHTS?</a>" below).
          </p>
        </li>
        <li>
          <p>
            <strong>Deliver targeted advertising to you.</strong>&nbsp;We may use your information to develop and
            display personalized content and advertising (and work with third parties who do so) tailored to your
            interests and/or location and to measure its effectiveness.
          </p>
        </li>
      </ul>
      <p><strong>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</strong></p>
      <p>
        <strong><em>In Short:</em></strong><em>&nbsp; We only share information with your consent, to comply with laws,
          to provide you with services, to protect your rights, or to fulfill business obligations.</em>
      </p>
      <p>We may process or share your data that we hold based on the following legal basis:</p>
      <ul>
        <li>
          <p>
            <strong>Consent:</strong> We may process your data if you have given us specific consent to use your
            personal information for a specific purpose.
          </p>
        </li>
        <li>
          <p>
            <strong>Legitimate Interests:</strong> We may process your data when it is reasonably necessary to
            achieve our legitimate business interests.
          </p>
        </li>
        <li>
          <p>
            <strong>Performance of a Contract:</strong> Where we have entered into a contract with you, we may
            process your personal information to fulfill the terms of our contract.
          </p>
        </li>
        <li>
          <p>
            <strong>Legal Obligations:</strong> We may disclose your information where we are legally required to do
            so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal
            process, such as in response to a court order or a subpoena (including in response to public authorities to
            meet national security or law enforcement requirements).
          </p>
        </li>
        <li>
          <p>
            <strong>Vital Interests:</strong> We may disclose your information where we believe it is necessary to
            investigate, prevent, or take action regarding potential violations of our policies, suspected fraud,
            situations involving potential threats to the safety of any person and illegal activities, or as evidence in
            litigation in which we are involved.
          </p>
        </li>
      </ul>
      <p>
        More specifically, we may need to process your data or share your personal information in the following
        situations:
      </p>
      <ul>
        <li>
          <p>
            <strong>Business Transfers.</strong> We may share or transfer your information in connection with, or
            during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of
            our business to another company.
          </p>
        </li>
        <li>
          <p>
            <strong>Other Users.&nbsp;</strong>When you share personal information (for example, by posting comments,
            contributions or other content to the Services) or otherwise interact with public areas of the Services, such
            personal information may be viewed by all users and may be publicly made available outside the Services in
            perpetuity. If you interact with other users of our Services and register for our Services through a social
            network (such as Facebook), your contacts on the social network will see your name, profile photo, and
            descriptions of your activity. Similarly, other users will be able to view descriptions of your activity,
            communicate with you within our Services, and view your profile.
          </p>
        </li>
        <li>
          <p>
            <strong>Offer Wall.</strong> Our App may display a third-party hosted "offer wall." Such an offer wall
            allows third-party advertisers to offer virtual currency, gifts, or other items to users in return for the
            acceptance and completion of an advertisement offer. Such an offer wall may appear in our App and be displayed
            to you based on certain data, such as your geographic area or demographic information. When you click on an
            offer wall, you will be brought to an external website belonging to other persons and will leave our App. A
            unique identifier, such as your user ID, will be shared with the offer wall provider in order to prevent fraud
            and properly credit your account with the relevant reward. Please note that we do not control third-party
            websites and have no responsibility in relation to the content of such websites. The inclusion of a link
            towards a third-party website does not imply an endorsement by us of such website. Accordingly, we do not make
            any warranty regarding such third-party websites and we will not be liable for any loss or damage caused by
            the use of such websites. In addition, when you access any third-party website, please understand that your
            rights while accessing and using those websites will be governed by the privacy notice and terms of service
            relating to the use of those websites.
          </p>
        </li>
      </ul>
      <p><strong>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</strong></p>
      <p>
        <strong><em>In Short:&nbsp;</em></strong><em>&nbsp;We may use cookies and other tracking technologies to
          collect and store your information.</em>
      </p>
      <p>
        We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store
        information. Specific information about how we use such technologies and how you can refuse certain cookies is
        set out in our Cookie Notice.
      </p>
      <p><strong>5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
      <p>
        <strong><em>In Short: &nbsp;</em></strong><em>If you choose to register or log in to our services using a
          social media account, we may have access to certain information about you.</em>
      </p>
      <p>
        Our Services offers you the ability to register and login using your third-party social media account details
        (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information
        about you from your social media provider. The profile information we receive may vary depending on the social
        media provider concerned, but will often include your name, email address, friends list, profile picture as well
        as other information you choose to make public on such social media platform.
      </p>
      <p>
        We will use the information we receive only for the purposes that are described in this privacy notice or that
        are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not
        responsible for, other uses of your personal information by your third-party social media provider. We recommend
        that you review their privacy notice to understand how they collect, use and share your personal information,
        and how you can set your privacy preferences on their sites and apps.
      </p>
      <p><strong>6. HOW LONG DO WE KEEP YOUR INFORMATION?</strong></p>
      <p>
        <strong><em>In Short:&nbsp;</em></strong><em>&nbsp;We keep your information for as long as necessary to fulfill
          the purposes outlined in this privacy notice unless otherwise required by law.</em>
      </p>
      <p>
        We will only keep your personal information for as long as it is necessary for the purposes set out in this
        privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting or
        other legal requirements). No purpose in this notice will require us keeping your personal information for
        longer than the period of time in which users have an account with us.
      </p>
      <p>
        When we have no ongoing legitimate business need to process your personal information, we will either delete or
        anonymize such information, or, if this is not possible (for example, because your personal information has been
        stored in backup archives), then we will securely store your personal information and isolate it from any
        further processing until deletion is possible.
      </p>
      <p><strong>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</strong></p>
      <p>
        <strong><em>In Short:&nbsp;</em></strong><em>&nbsp;We aim to protect your personal information through a system
          of organizational and technical security measures.</em>
      </p>
      <p>
        We have implemented appropriate technical and organizational security measures designed to protect the security
        of any personal information we process. However, despite our safeguards and efforts to secure your information,
        no electronic transmission over the Internet or information storage technology can be guaranteed to be 100%
        secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will
        not be able to defeat our security, and improperly collect, access, steal, or modify your information. Although
        we will do our best to protect your personal information, transmission of personal information to and from our
        Services is at your own risk. You should only access the Services within a secure environment.
      </p>
      <p><strong>8. DO WE COLLECT INFORMATION FROM MINORS?</strong></p>
      <p>
        <strong><em>In Short:</em></strong><em>&nbsp; We do not knowingly collect data from or market to children under
          18 years of age.</em>
      </p>
      <p>
        We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you
        represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such
        minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of
        age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data
        from our records. If you become aware of any data we may have collected from children under age 18, please
        contact us at info@fantasykombat.com.
      </p>
      <p><strong>9. WHAT ARE YOUR PRIVACY RIGHTS?</strong></p>
      <p>
        <strong><em>In Short:</em></strong><em>&nbsp; In some regions, such as the European Economic Area (EEA) and
          United Kingdom (UK), you have rights that allow you greater access to and control over your personal
          information. You may review, change, or terminate your account at any time.</em>
      </p>
      <p>
        In some regions (like the EEA and UK), you have certain rights under applicable data protection laws. These may
        include the right (i) to request access and obtain a copy of your personal information, (ii) to request
        rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable,
        to data portability. In certain circumstances, you may also have the right to object to the processing of your
        personal information. To make such a request, please use the <a href="https://www.fantasykombat.com/contact-us">contact details</a> provided
        below. We will consider and act upon any request in accordance with applicable data protection laws.
      </p>
      <p>
        If we are relying on your consent to process your personal information, you have the right to withdraw your
        consent at any time. Please note however that this will not affect the lawfulness of the processing before its
        withdrawal, nor will it affect the processing of your personal information conducted in reliance on lawful
        processing grounds other than consent.
      </p>
      <p>
        If you are a resident in the EEA or UK and you believe we are unlawfully processing your personal information,
        you also have the right to complain to your local data protection supervisory authority. You can find their
        contact details here: <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>.
      </p>
      <p>
        If you are a resident in Switzerland, the contact details for the data protection authorities are available
        here: <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">https://www.edoeb.admin.ch/edoeb/en/home.html</a>.
      </p>
      <p>If you have questions or comments about your privacy rights, you may email us at info@fantasykombat.com.</p>
      <p><strong>Account Information</strong></p>
      <p>
        If you would at any time like to review or change the information in your account or terminate your account,
        you can:
      </p>
      <ul>
        <li>Log in to your account settings and update your user account.</li>
      </ul>
      <p>
        Upon your request to terminate your account, we will deactivate or delete your account and information from our
        active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems,
        assist with any investigations, enforce our Terms of Use and/or comply with applicable legal requirements.
      </p>
      <p>
        <strong>Cookies and similar technologies:</strong> Most Web browsers are set to accept cookies by default. If
        you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to
        remove cookies or reject cookies, this could affect certain features or services of our Services. To opt-out of
        interest-based advertising by advertisers on our Services visit <a href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a>.
      </p>
      <p>
        <strong>Opting out of email marketing:</strong> You can unsubscribe from our marketing email list at any time
        by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided
        below. You will then be removed from the marketing email list — however, we may still communicate with you, for
        example to send you service-related emails that are necessary for the administration and use of your account, to
        respond to service requests, or for other non-marketing purposes. To otherwise opt-out, you may:
      </p>
      <ul>
        <li>Contact us using the contact information provided.</li>
      </ul>
      <p><strong>10. CONTROLS FOR DO-NOT-TRACK FEATURES</strong></p>
      <p>
        Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT")
        feature or setting you can activate to signal your privacy preference not to have data about your online
        browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and
        implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any
        other mechanism that automatically communicates your choice not to be tracked online. If a standard for online
        tracking is adopted that we must follow in the future, we will inform you about that practice in a revised
        version of this privacy notice.&nbsp;
      </p>
      <p><strong>11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</strong></p>
      <p>
        <strong><em>In Short:&nbsp;</em></strong><em>&nbsp;Yes, if you are a resident of California, you are granted
          specific rights regarding access to your personal information.</em>
      </p>
      <p>
        California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are
        California residents to request and obtain from us, once a year and free of charge, information about categories
        of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and
        addresses of all third parties with which we shared personal information in the immediately preceding calendar
        year. If you are a California resident and would like to make such a request, please submit your request in
        writing to us using the contact information provided below.
      </p>
      <p>
        If you are under 18 years of age, reside in California, and have a registered account with a Service, you have
        the right to request removal of unwanted data that you publicly post on the Services. To request removal of such
        data, please contact us using the contact information provided below, and include the email address associated
        with your account and a statement that you reside in California. We will make sure the data is not publicly
        displayed on the Services, but please be aware that the data may not be completely or comprehensively removed
        from all our systems (e.g. backups, etc.).
      </p>
      <p><strong>CCPA Privacy Notice</strong></p>
      <p>The California Code of Regulations defines a "resident" as:</p>
      <p>(1) every individual who is in the State of California for other than a temporary or transitory purpose and</p>
      <p>
        (2) every individual who is domiciled in the State of California who is outside the State of California for a
        temporary or transitory purpose
      </p>
      <p>All other individuals are defined as "non-residents."</p>
      <p>
        If this definition of "resident" applies to you, we must adhere to certain rights and obligations regarding
        your personal information.
      </p>
      <p><strong>What categories of personal information do we collect?</strong></p>
      A. Identifiers | Contact details, such as real name, alias, postal address,
      telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address,
      email address and account name.
      <p>
        We may also collect other personal information outside of these categories instances where you interact with us
        in-person, online, or by phone or mail in the context of:
      </p>
      <ul>
        <li><p>Receiving help through our customer support channels;</p></li>
        <li><p>Participation in customer surveys or contests; and</p></li>
        <li><p>Facilitation in the delivery of our Services and to respond to your inquiries.</p></li>
      </ul>
      <p><strong>How do we use and share your personal information?</strong></p>
      <p>More information about our data collection and sharing practices can be found in this privacy notice.</p>
      <p>
        You may contact us by email at&nbsp;info@fantasykombat.com, by visiting <a
          href="https://www.fantasykombat.com/contact-us"
        >https://www.fantasykombat.com/contact-us</a>, or by referring to
        the contact details at the bottom of this document.
      </p>
      <p>
        If you are using an authorized agent to exercise your right to opt-out we may deny a request if the authorized
        agent does not submit proof that they have been validly authorized to act on your behalf.
      </p>
      <p><strong>Will your information be shared with anyone else?</strong></p>
      <p>
        We may disclose your personal information with our service providers pursuant to a written contract between us
        and each service provider. Each service provider is a for-profit entity that processes the information on our
        behalf.
      </p>
      <p>
        We may use your personal information for our own business purposes, such as for undertaking internal research
        for technological development and demonstration. This is not considered to be "selling" of your personal
        data.
      </p>
      <p>
        FantasyKombat&nbsp;has not disclosed or sold any personal information to third parties for a business or
        commercial purpose in the preceding 12 months. FantasyKombat will not sell personal information in the future
        belonging to website visitors, users and other consumers.
      </p>
      <p><strong>Your rights with respect to your personal data</strong></p>
      <p>Right to request deletion of the data - Request to delete</p>
      <p>
        You can ask for the deletion of your personal information. If you ask us to delete your personal information,
        we will respect your request and delete your personal information, subject to certain exceptions provided by
        law, such as (but not limited to) the exercise by another consumer of his or her right to free speech, our
        compliance requirements resulting from a legal obligation or any processing that may be required to protect
        against illegal activities.
      </p>
      <p>Right to be informed - Request to know</p>
      <p>Depending on the circumstances, you have a right to know:</p>
      <ul>
        <li><p>whether we collect and use your personal information;</p></li>
        <li><p>the categories of personal information that we collect;</p></li>
        <li><p>the purposes for which the collected personal information is used;</p></li>
        <li><p>whether we sell your personal information to third parties;</p></li>
        <li><p>the categories of personal information that we sold or disclosed for a business purpose;</p></li>
        <li>
          <p>
            the categories of third parties to whom the personal information was sold or disclosed for a business
            purpose; and
          </p>
        </li>
        <li><p>the business or commercial purpose for collecting or selling personal information.</p></li>
      </ul>
      <p>
        In accordance with applicable law, we are not obligated to provide or delete consumer information that is
        de-identified in response to a consumer request or to re-identify individual data to verify a consumer
        request.
      </p>
      <p>Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights</p>
      <p>We will not discriminate against you if you exercise your privacy rights.</p>
      <p>Verification process</p>
      <p>
        Upon receiving your request, we will need to verify your identity to determine you are the same person about
        whom we have the information in our system. These verification efforts require us to ask you to provide
        information so that we can match it with information you have previously provided us. For instance, depending on
        the type of request you submit, we may ask you to provide certain information so that we can match the
        information you provide with the information we already have on file, or we may contact you through a
        communication method (e.g. phone or email) that you have previously provided to us. We may also use other
        verification methods as the circumstances dictate.
      </p>
      <p>
        We will only use personal information provided in your request to verify your identity or authority to make the
        request. To the extent possible, we will avoid requesting additional information from you for the purposes of
        verification. If, however, we cannot verify your identity from the information already maintained by us, we may
        request that you provide additional information for the purposes of verifying your identity, and for security or
        fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying
        you.
      </p>
      <p>Other privacy rights</p>
      <ul>
        <li><p>you may object to the processing of your personal data.</p></li>
        <li>
          <p>
            you may request correction of your personal data if it is incorrect or no longer relevant, or ask to
            restrict the processing of the data.
          </p>
        </li>
        <li>
          <p>
            you can designate an authorized agent to make a request under the CCPA on your behalf. We may deny a
            request from an authorized agent that does not submit proof that they have been validly authorized to act on
            your behalf in accordance with the CCPA.
          </p>
        </li>
        <li>
          <p>
            you may request to opt-out from future selling of your personal information to third parties. Upon
            receiving a request to opt-out, we will act upon the request as soon as feasibly possible, but no later than
            15 days from the date of the request submission.
          </p>
        </li>
      </ul>
      <p>
        To exercise these rights, you can contact us&nbsp;by email at info@fantasykombat.com, by visiting <a
          href="https://www.fantasykombat.com/contact-us"
        >https://www.fantasykombat.com/contact-us</a>, or by referring to
        the contact details at the bottom of this document. If you have a complaint about how we handle your data, we
        would like to hear from you.
      </p>
      <p><strong>12. DO WE MAKE UPDATES TO THIS NOTICE?</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
      <p>
        <em><strong>In Short:&nbsp;</strong> Yes, we will update this notice as necessary to stay compliant with
          relevant laws.</em>
      </p>
      <p>
        We may update this privacy notice from time to time. The updated version will be indicated by an updated
        "Revised" date and the updated version will be effective as soon as it is accessible. If we make material
        changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by
        directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of
        how we are protecting your information.
      </p>
      <p><strong>13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
      <p>
        If you have questions or comments about this notice, you may email us at info@fantasykombat.com or vie our online form  <a
          href="https://www.fantasykombat.com/contact-us"
        >https://www.fantasykombat.com/contact-us</a>
      </p>
      <br>
      <br>
      <br>
    </v-card>
  </div>
</template>

<script>
import {mdiAlert} from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiAlert,
      },
    }
  },
}
</script>

<style lang="scss">
</style>
